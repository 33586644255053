// singly linked list
export default function list(){
	this.head = null;
	this.tail = null;

	function node(data){
		this.data = data;
		this.next = null;
	}

	this.add_to_tail = function(data){
		let n = new node(data);
		if (this.head === null) {
			this.head = n;
			this.tail = n;
		} else {
			this.tail.next = n;
			this.tail = this.tail.next;
		}
	}

	this.remove_from_head = function(){
		let n = this.head;
		this.head = this.head.next;
		return n.data;
	}

	this.cycle= function(){
		if(this.head === null) return;

		// add the head to the tail
		this.tail.next = this.head;

		// update the tail pointer
		this.tail = this.head;

		// update the head pointer
		this.head = this.head.next;

		// remove the next pointer from the now tail
		this.tail.next = null;
	}
}
