import list from './list.js';
import images from '../assets/header_images/*.jpeg';

document.addEventListener("DOMContentLoaded", doCollage);

function doCollage(){
	let max_img_width = 0; // used to get the largest image width

	let scroll_speed = .3;

	// parameters
	let num_rows = 1;
	let collage_height = 500;
	let row_height = collage_height/num_rows;

	// make one list per collage row and fill them equally with images
	let rows = [];
	for(let r = 0; r < num_rows; r++){
		rows[r] = new list();
	}

	// fill all the rows with images
	let img_fill_counter = 0;
	for (let img_name in images){

		//make and style the new image
		let img = new Image();
		img.src = images[img_name];
		img.style.height = row_height + "px";
		//img.style.borderWidth = "0px 4px";
		//img.style.borderStyle = "solid";
		//img.style.borderColor = "maroon";
		//img.style.padding = "0px 4px";


		// track max image width
		let scale_factor = row_height/img.height;
		let computed_width = img.width*scale_factor;
		if (computed_width > max_img_width){
			max_img_width = computed_width;
		}

		// put the image in a list
		rows[img_fill_counter%num_rows].add_to_tail(img);
		img_fill_counter++;
	}

	// make the DOM counterpart of the row lists

	// get and style the collage element
	let collage = document.getElementById("collage");
	collage.style.overflow = "hidden";
	collage.style.height = collage_height + "px";


	// to extend the row width to hide swapping out images
	let extra_width = 2*max_img_width; // formerly 3*

	// to set the range of motion for the collage rows
	let left_edge = -extra_width/2;
	let start_position = -max_img_width/2;

	let DOM_rows = [];
	let DOM_row_positions = [];

	for (let r = 0; r < num_rows; r++){
		let row = document.createElement("div");
		row.style.position = "relative";
		row.style.height = row_height+ "px";
		row.style.width = "calc(100% + " + extra_width + "px)";
		row.style.left = start_position + "px";
		DOM_row_positions[r] = start_position;
		row.style.display = "flex";
		row.class = "collage-row";
		row.id = "collage-row-" + r+1;
		DOM_rows.push(row);
		collage.appendChild(row);
	}

	// add the images from row lists to the DOM rows
	for (let r = 0; r < num_rows; r++){
		let current_node = rows[r].head;
		while(current_node !== null){
			DOM_rows[r].appendChild(current_node.data);
			current_node = current_node.next;
		}
	}

	function cycleImagesInRow(r){
		DOM_rows[r].removeChild(rows[r].head.data);
		DOM_rows[r].appendChild(rows[r].head.data);
		rows[r].cycle();
	}

	function slide(){
		for(let r = 0; r < num_rows; r++){
			DOM_row_positions[r]-= scroll_speed;
			if (DOM_row_positions[r] <= left_edge){
				DOM_row_positions[r] += rows[r].head.data.width;
				cycleImagesInRow(r);
			}
			DOM_rows[r].style.left = DOM_row_positions[r] + "px";
		}

		window.requestAnimationFrame(slide);
	}

	window.requestAnimationFrame(slide);
}
